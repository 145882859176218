import { environment } from "src/environments/environment";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// -----------------------------------------------------------------------------
import { LayoutWwwComponent, LayoutWwwLandingComponent } from "src/project/layout";
// -----------------------------------------------------------------------------

const routes: Routes = [
  {
    path: "",
    component: LayoutWwwComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages/module").then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: "page",
    component: LayoutWwwComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-public/module").then((m) => m.PagesPublicModule),
      },
    ],
  },
  {
    path: "pricing",
    component: LayoutWwwComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-pricing/module").then((m) => m.PagesPricingModule),
      },
    ],
  },
  {
    path: "landing",
    component: LayoutWwwLandingComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-landing/module").then((m) => m.PagesPublicModule),
      },
    ],
  },
  {
    path: "**",
    component: LayoutWwwComponent,
    loadChildren: () => import("../project/modules/404/module").then((m) => m.Page404Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
