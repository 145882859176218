<section class="pb-10">
  <nav class="flex justify-between items-center py-6 px-10 relative">
    <a [routerLink]="['/']" class="text-lg font-bold">
      <img class="h-20" src="/assets/images/logo-header.png" alt="" />
    </a>
    <div class="xl:hidden">
      <button class="navbar-burger focus:outline-none text-indigo-900 hover:text-indigo-800">
        <svg class="block h-6 w-6" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Mobile menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>
    </div>
    <!--
    <ul class="hidden xl:flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <li><a [routerLink]="['/page/service']" class="text-lg mr-10 2xl:mr-16 font-extrabold hover:text-indigo-800">Serviços</a></li>
      <li><a [routerLink]="['/page/company']" class="text-lg font-extrabold hover:text-indigo-800">Empresa</a></li>
    </ul>
    -->
    <div class="hidden xl:flex items-center">
      <a [routerLink]="['/page/contact']" class="inline-block py-4 px-6 text-center leading-6 text-lg text-white font-extrabold bg-indigo-800 hover:bg-indigo-900 border-3 border-indigo-900 shadow rounded transition duration-200">Entre em contato</a>
    </div>
  </nav>
  <div class="hidden navbar-menu relative z-50">
    <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
    <nav class="fixed top-0 left-0 bottom-0 flex flex-col w-full md:w-5/6 max-w-sm py-8 px-8 bg-white border-r overflow-y-auto">
      <div class="flex items-center mb-8">
        <a [routerLink]="['/']" class="mr-auto text-2xl font-bold leading-none" href="#">
          <img class="h-6" src="/assets/images/logo-header.png" alt="" width="auto" />
        </a>
        <button class="navbar-close">
          <svg class="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <!--
      <div class="my-auto">
        <ul class="py-10">
          <li class="mb-1"><a [routerLink]="['/page/service']" class="block p-4 text-lg font-extrabold hover:bg-gray-50 rounded">Serviços</a></li>
          <li class="mb-1"><a [routerLink]="['/page/company']" class="block p-4 text-lg font-extrabold hover:bg-gray-50 rounded">Empresa</a></li>
        </ul>
      </div>
      -->
      <div>
        <a [routerLink]="['/page/contact']" class="block py-4 px-6 text-center leading-6 text-lg text-white font-extrabold bg-indigo-800 hover:bg-indigo-900 border-3 border-indigo-900 shadow rounded transition duration-200">Entre em contato</a>
      </div>
    </nav>
  </div>
</section>
