import { environment } from "src/environments/environment";
import { Component, OnInit, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { Router, NavigationStart, NavigationEnd, RoutesRecognized, ActivatedRoute } from "@angular/router";
// [[Providers]]

// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: "app-www-landing-footer",
  templateUrl: "./component.html",
  styleUrls: ["./component.css"],
})
export class WwwLandingFooterComponent implements OnDestroy {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private rendererFactory: RendererFactory2
  ) {}

  currentRoute: string = "";
  ngOnInit(): void {
    this.ipBuildAppDependencies();
  }

  /**
   * build ecommerce dependencies
   */
  ipBuildAppDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
  }

  /**
   * after view init
   */
  ngAfterViewInit() {}

  /**
   * on destroy
   */
  ngOnDestroy() {}
}
