<footer>
  <section class="bg-white">
    <div class="pt-26 border-3 border-l-0 border-r-0 border-indigo-900">
      <div class="pb-16 border-b-3 border-indigo-900">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap justify-between items-center">
            <div class="w-full md:w-1/3 mb-12">
              <a [routerLink]="['/']" class="inline-block mx-auto mb-8">
                <img class="h-24" src="/assets/images/logo-footer.png" alt="iPORTO" />
              </a>
              <p class="text-base font-bold leading-5 text-justify">A iPORTO.dev, é uma empresa do grupo <a href="https://iporto.com.br" target="_blank">iPORTO.COM</a> que oferece serviços na área de <strong>criação de software</strong> e <strong>consultoria</strong> em tecnologia.</p>
            </div>
            <div class="w-full">
              <div class="flex flex-wrap items-center -mb-6">
                <a [routerLink]="['/page/service']" class="inline-block mr-4 sm:mr-8 lg:mr-14 mb-6 text-lg font-extrabold hover:text-indigo-800">Serviços</a>
                <a [routerLink]="['/page/company']" class="inline-block mr-4 sm:mr-8 lg:mr-14 mb-6 text-lg font-extrabold hover:text-indigo-800">Empresa</a>
                <a [routerLink]="['/page/case']" class="inline-block mr-4 sm:mr-8 lg:mr-14 mb-6 text-lg font-extrabold hover:text-indigo-800">Casos de Sucesso</a>
                <a [routerLink]="['/page/contract-privacy']" class="inline-block mr-4 sm:mr-8 lg:mr-14 mb-6 text-lg font-extrabold hover:text-indigo-800">Política de Privacidade</a>
                <a [routerLink]="['/page/contract-lgpd']" class="inline-block mr-4 sm:mr-8 lg:mr-14 mb-6 text-lg font-extrabold hover:text-indigo-800">LGPD</a>
                <a [routerLink]="['/page/contact']" class="inline-block mr-4 sm:mr-8 lg:mr-14 mb-6 text-lg font-extrabold hover:text-indigo-800">Contato</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container px-4 pt-16 pb-24 mx-auto">
        <div class="flex flex-wrap justify-between">
          <div class="w-full lg:w-auto mb-12 lg:mb-0">
            <p class="text-left text-xs font-bold">© iPORTO.COM é uma marca registrada, fundada em 2003.</p>
            <p class="text-left text-xs font-normal">iPORTO.COM SERVIÇOS DE INTERNET LTDA / CNPJ: 09.249.136/0001-04. Porto Alegre - RS.</p>
          </div>
          <div class="w-full lg:w-auto flex items-center justify-center">
            <a class="inline-block text-indigo-900 hover:text-indigo-800 mr-8" href="https://www.facebook.com/iporto.dev" target="_blank">
              <svg width="17" height="30" viewbox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.7247 29.5454V16.2767H15.2637L15.9446 11.1041H10.7247V7.80212C10.7247 6.305 11.1469 5.28473 13.3381 5.28473L16.1284 5.28361V0.657045C15.6458 0.59554 13.9895 0.454529 12.0616 0.454529C8.03601 0.454529 5.28 2.86466 5.28 7.28983V11.1041H0.727295V16.2767H5.28V29.5454H10.7247Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
            <a class="inline-block text-indigo-900 hover:text-indigo-800" href="https://www.instagram.com/iportodev" target="_blank">
              <svg width="34" height="34" viewbox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.6677 0.636353H24.3319C29.3122 0.636353 33.3638 4.68801 33.3636 9.6679V24.3321C33.3636 29.312 29.3122 33.3636 24.3319 33.3636H9.6677C4.68782 33.3636 0.636353 29.3122 0.636353 24.3321V9.6679C0.636353 4.68801 4.68782 0.636353 9.6677 0.636353ZM24.3321 30.4599C27.711 30.4599 30.4601 27.711 30.4601 24.3321H30.4599V9.6679C30.4599 6.28913 27.7109 3.54007 24.3319 3.54007H9.6677C6.28893 3.54007 3.54007 6.28913 3.54007 9.6679V24.3321C3.54007 27.711 6.28893 30.4601 9.6677 30.4599H24.3321ZM8.42856 17.0002C8.42856 12.2737 12.2736 8.42856 17 8.42856C21.7263 8.42856 25.5714 12.2737 25.5714 17.0002C25.5714 21.7265 21.7263 25.5714 17 25.5714C12.2736 25.5714 8.42856 21.7265 8.42856 17.0002ZM11.38 17C11.38 20.0988 13.9012 22.6198 17 22.6198C20.0988 22.6198 22.62 20.0988 22.62 17C22.62 13.901 20.099 11.3798 17 11.3798C13.901 11.3798 11.38 13.901 11.38 17Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</footer>
