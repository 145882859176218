// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var PublicSuffixList = require("psl");
var domainObject = PublicSuffixList.parse(document.domain);
// {
//     "input": "www.iporto.io",
//     "tld": "io",
//     "sld": "iporto",
//     "domain": "iporto.io",
//     "subdomain": "www",
//     "listed": true
// }

export const environment = {
  production: true,
  app_name: "iPORTO.dev",

  // production
  site_url: "https://iporto.dev",
  app_url: "https://app.iporto.dev",
  api_url: "https://api.iporto.dev/api/",
  api_doc: "https://api.iporto.dev/docs",
  api_url_auth: "https://api.iporto.dev/api/",
  api_url_account: "https://api.iporto.dev/api/",

  http_garbage_collection: "https://http-collection.iporto.io/api/collective/client/pkg/http-collection/296cad22-e6d1-44b7-8c28-5973d5ba4dec/action/request",

  page: {
    url: document.URL,
    domain: document.domain,
    expression: document.location.pathname,
  },
};
