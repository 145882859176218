import { environment } from "src/environments/environment";
import "rxjs/add/operator/toPromise";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// [[Providers]]
import { ErrorService } from "~services/error.service";
import { DateService } from "~services/date.service";
// ]
@Injectable()
export class CollectiveCoreApplicationFaqService {
  authenticated_user: any;

  constructor(private router: Router, public http: HttpClient, public error: ErrorService, public dateService: DateService) {}

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = "";
    for (let entry in data) {
      querystring += entry + "=" + encodeURIComponent(data[entry]) + "&";
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == "object") {
        if (data[entry] == null) {
          postData[entry] = null;
        } else if (Object.prototype.toString.call(data[entry]) === "[object Date]") {
          postData[entry] = this.dateService.convertDatePickerTimeToMySQLTime(data[entry]);
        } else {
          postData[entry] = data[entry];
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  index(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + "collective/core/application/faq?" + querystring, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + "collective/core/application/faq/" + data.id + "?" + querystring, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return request;
  }
}
