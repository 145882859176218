<header class="bg-white pt-4 -mt-4 z-[9999999]">
  <div class="border-b border-gray-200"></div>
  <div class="max-w-5xl mx-auto my-[15px]">
    <div class="flex justify-between items-center font-barlow text-[#171819]">
      <div class="mx-4 sm:mx-0">
        <a [routerLink]="['/']">
          <img src="/assets/images/logo-header-mini.png" class="h-8" alt="Dream Art" />
        </a>
      </div>
      <!--
      <div class="hidden sm:flex space-x-[54px]">
        <div class="flex justify-center items-center space-x-[75px]">
          <div class="flex justify-center items-center space-x-4">
            <div class="bg-[#DEDBD6] rounded-full p-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </div>
            <div>
              <p class="font-bold">Precisa de Ajuda?</p>
              <p class="-pt-2">
                <a href="https://wa.me/+5511910783311" target="_blank"> 11 91078-3311 </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>
  <div class="border-b border-gray-200"></div>
</header>
